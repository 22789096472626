<template>
  <div class="index-main">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
		
    }
  },
  mounted() {
	  
  },
  methods: {
   
  }
}
</script>
<style lang="scss" scoped>
	.index-main{width: 100%;height: 100%;box-sizing: border-box;}

</style>
